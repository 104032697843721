import { useDropzone } from "react-dropzone";
import './dropZoneUploader.scss'

const DropZoneUploader = ({
	preview,
	acceptPreviewHandler,
	className,
	text = 'Прикрепите файл',
}) => {
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptPreviewHandler,
		maxFiles: 1,
	});

	return (
		<section className={`DropZoneRoot ${className}`}  {...getRootProps()}>
				<div className="dropzone">
					{preview ? (
						<img
							src={typeof preview === "string" ? preview : URL.createObjectURL(preview.file)}
							alt=''
						/>
					) : (
						<div className="dropzone">
							<b>{text}</b>
						</div>
					)}
					<input {...getInputProps()} />
				</div>
		</section>
	)
};

export default DropZoneUploader;