import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/adminPageWrapper/adminPageWrapper";
import './mainPage.scss';

const MainPage = () => {
	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>hello "{ADMIN_ROLE}"</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default MainPage;