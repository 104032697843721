const ADMIN_ROLE = 'admin';
const MERCHANT_ROLE = 'merchant';
const COURIER_ROLE = 'courier';
const USER_ROLE = 'user';

const AllRolesArray = [
	{ label: 'Администратор', value: ADMIN_ROLE},
	{ label: 'Продавец', value: MERCHANT_ROLE},
	{ label: 'Курьер', value: COURIER_ROLE},
	{ label: 'Пользователь', value: USER_ROLE}
];

const AdminPanelRolesArray = [
	{ label: 'Администратор', value: ADMIN_ROLE},
	{ label: 'Продавец', value: MERCHANT_ROLE}
];


export {
	ADMIN_ROLE,
	MERCHANT_ROLE,
	COURIER_ROLE,
	USER_ROLE,
	AllRolesArray,
	AdminPanelRolesArray
};