const ROUTES = {
	auth: '/auth',
	merchant: {
		main: '/merchant',
		marketplace: '/merchant/:marketplaceId/marketplace',
		marketplace_edit: '/merchant/:marketplaceId/marketplace/edit',
		marketplace_create: '/merchant/marketplace/create',
		products: '/merchant/:marketplaceId/products',
		categories: '/merchant/:marketplaceId/categories',
		productsCreate: '/merchant/:marketplaceId/products/create',
		productsEdit: '/merchant/:marketplaceId/products/:productId',
		orders: '/merchant/:marketplaceId/orders',
		order: '/merchant/:marketplaceId/orders/:orderId',
		notifications: '/merchant/:marketplaceId/notifications',
		statistics: '/merchant/:marketplaceId/statistics',
		promotional: '/merchant/:marketplaceId/promotional',
	},
	admin: {
		main: '/admin',
		users: '/admin/users',
		users_create: '/admin/users/create',
		users_edit: '/admin/users/:userId',
	}
};

export default ROUTES;